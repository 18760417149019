<template>
  <v-container class="container">
    <div class="primary-container">
      <h1><img src="../assets/bandeira-rs.svg" />COLAB RS</h1>
      <p>
        Uma rede de especialistas voluntários, apoiando a reconstrução das pequenas empresas do Rio Grande do Sul.
      </p>
      <div class="btn-group">
        <div class="btn-item">
          <CustomButton block text  :typeButton="'primary_out_green'" @click="redirectLogin">FAZER LOGIN</CustomButton>
        </div>

        <!-- <div class="btn-item">
          <a href="#helpSection">
            <CustomButton block text  :typeButton="'primary_out_green'">QUERO PARTICIPAR</CustomButton>
          </a>
        </div> -->
      </div>
    </div>

    <div class="secondary-container">
      <h1>Te ajudamos a encontrar caminhos para superar os desafios</h1>
      <p>
        Aqui você se conecta gratuitamente com consultores especializados nos principais pilares de funcionamento do teu
        negócio, para ajudar a tua micro ou pequeno empresa nesse momento de crise.
      </p>
      <div class="labels-group">
        <div class="label-helper" v-for="item in labelsHelper" :key="item">
          <span>{{ item }}</span>
        </div>
      </div>
    </div>

    <!-- TODO: OTHER COMPONENT (MAYBE??) -->
    <div class="tertiary-container">
      <img src="@/assets/handshake.svg">
      <h1>Suporte especializado, sem burocracia</h1>
      <p>* No momento não estamos mais aceitando novos cadastros. Entre em contato para mais informações.</p>
    </div>

    <!-- TODO: OTHER COMPONENT -->
    <!-- <div class="quaternary-container" id="helpSection">
      <h1>QUERO PARTICIPAR</h1>
      <v-form>
        <v-row>
          <v-col cols="12" class="pa-2">
            <v-text-field hide-details="auto" ref="name" outlined dense v-model="form.name" :rules="form.rules.name"
              label="Nome" required :disabled="loading"></v-text-field>
          </v-col>

          <v-col cols="12" sm="5" md="5" lg="5" class="pa-2">
            <v-text-field hide-details="auto" ref="cpf" outlined dense v-model="form.cpf" :rules="form.rules.cpf"
              v-mask="'###.###.###-##'" label="CPF" :disabled="loading" maxlength="14"></v-text-field>
          </v-col>

          <v-col cols="12" sm="7" md="7" lg="7" class="pa-2">
            <v-text-field hide-details="auto" ref="cnpj" outlined dense v-model="form.cnpj" :rules="form.rules.cnpj"
              label="CNPJ" :disabled="loading" maxlength="18" v-mask="'##.###.###/####-##'"></v-text-field>
          </v-col>

          <v-col cols="12" sm="5" md="5" lg="5" class="pa-2">
            <v-text-field hide-details="auto" ref="email" outlined dense v-model="form.email" :rules="form.rules.email"
              label="E-mail" required :disabled="loading"></v-text-field>
          </v-col>

          <v-col cols="12" sm="7" md="7" lg="7" class="pa-2">
            <v-text-field ref="phone" outlined dense v-model="form.phone" :rules="form.rules.phone"
              label="Telefone (whatsapp)" required return-masked-value v-mask="'(##) # ####-####'" :disabled="loading"
              hide-details="auto"></v-text-field>
          </v-col>

          <v-col cols="12" sm="5" md="5" lg="5" class="pa-2">
            <v-text-field hide-details="auto" ref="uf" outlined dense v-model="form.uf" :rules="form.rules.uf"
              label="UF" required :disabled="loading" maxlength="2"></v-text-field>
          </v-col>

          <v-col cols="12" sm="7" md="7" lg="7" class="pa-2">
            <v-text-field ref="city" outlined dense v-model="form.city" :rules="form.rules.city" label="Cidade" required
              :disabled="loading" hide-details="auto"></v-text-field>
          </v-col>

          <v-col cols="12" class="pa-2">
            <v-text-field hide-details="auto" ref="username" outlined dense v-model="form.username"
              :rules="form.rules.username" label="Usuário" required :disabled="loading"></v-text-field>
          </v-col>

          <v-col cols="12" class="pa-2">
            <v-text-field ref="password" outlined hide-details="auto" dense v-model="form.password"
              :rules="form.rules.password" label="Senha" required :disabled="loading"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show"
              :type="show ? 'text' : 'password'"></v-text-field>
          </v-col>

          <v-col cols="12 pa-0">
            <v-checkbox class="checkbox pa-0" v-model="acceptedTerms" label="Concordo com o uso dos meus dados pessoais na plataforma Colab RS
               para que minha localização, nome e telefone de contato sejam disponibilizados 
               a terceiros com a finalidade de ter apoio na recuperação da minha empresa." required />
          </v-col>
        </v-row>
        <CustomButton block text large :typeButton="'primary'" :loading="form.loading" @click="register()">Criar
        </CustomButton>
      </v-form>
    </div> -->

    <div class="quinary-container">
      <h1>QUEM APOIA</h1>
      <v-carousel>
        <v-carousel-item cover v-if="!isScreenWide">
          <img src="../assets/logo-governo-rs.svg" />
          <img src="../assets/logo-sebrae.svg" />
        </v-carousel-item>

        <v-carousel-item cover v-if="!isScreenWide">
          <img src="../assets/logo-reginp.svg" />
          <img src="../assets/logo-db.svg" height="52px" />
        </v-carousel-item>
        <v-carousel-item cover v-if="!isScreenWide">
          <img src="../assets/logo-atropotec.png" height="40px" />
          <img src="../assets/logo-AGS_branco.png" height="50px" />
        </v-carousel-item>
        <v-carousel-item cover v-if="!isScreenWide">
          <img src="../assets/Logo-ADVB.jpg" height="55px" />
          <img src="../assets/logo-pacto-alegre.png" height="55px" />
        </v-carousel-item>
        <v-carousel-item cover v-if="!isScreenWide">
          <img src="../assets/aliança-inovacao.webp" width="120px" height="55px" />
          <img src="../assets/caldeira.png" height="55px" />
        </v-carousel-item>
        <v-carousel-item cover v-if="!isScreenWide">
          <img src="../assets/sict-gov.png" height="55px" />
        </v-carousel-item>


        <!-- items to desktop -->
        <v-carousel-item cover v-if="isScreenWide">
          <img src="../assets/logo-governo-rs.svg" />
          <img src="../assets/logo-sebrae.svg" />
          <img src="../assets/logo-reginp.svg" />
          <img src="../assets/logo-db.svg" height="52px" />
          <img src="../assets/logo-atropotec.png" height="52px" />
          <img src="../assets/logo-AGS_branco.png" height="50px" />
        </v-carousel-item>
        <v-carousel-item cover v-if="isScreenWide">
          <img src="../assets/Logo-ADVB.jpg" height="55px" />
          <img src="../assets/logo-pacto-alegre.png" height="55px" />
          <img src="../assets/aliança-inovacao.webp" width="120px" height="40px" />
          <img src="../assets/caldeira.png" height="55px" />
          <img src="../assets/sict-gov.png" height="55px" />
        </v-carousel-item>
      </v-carousel>
    </div>

    <div class="septenary-container">
      <h1>PERGUNTAS FREQUENTES</h1>
      <div>
        <div class="card" v-for="(item, index) in items" :key="index">
          <div class="d-flex justify-space-between align-center">
            <h2>{{ item.title }}</h2>
            <v-btn icon @click="toggle(index)" class="icon">
              <v-icon style="width: 1%;">
                {{ item.isOpen ? 'mdi-menu-up' : 'mdi-menu-down' }}
              </v-icon>
            </v-btn>
          </div>
          <div class="description" v-if="item.isOpen">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>


    <div class="footer">
      <h1><img src="../assets/bandeira-rs.svg" />COLAB RS</h1>
    </div>
  </v-container>
</template>

<script>
import CustomButton from '../components/btn/Btn.vue';
import router from '../router';
import axios from 'axios';
import * as jwt from "jwt-decode";

export default {
  components: {
    // Btn
    CustomButton
  },
  data: () => {
    return {
      isScreenWide: window.innerWidth > 600,
      fields: [
        { label: 'Nome completo', model: 'name' },
        { label: 'CPF', model: 'cpf' },
        { label: 'CNPJ', model: 'cnpj' },
        { label: 'E-mail', model: 'email' },
        { label: 'Telefone Whatsapp', model: 'phone' },
        { label: 'UF', model: 'uf' },
        { label: 'Cidade', model: 'city' },
        { label: 'Usuário', model: 'username' },
        { label: 'Senha', model: 'password' }
      ],
      form: {
        name: '',
        cpf: '',
        cnpj: '',
        email: '',
        phone: '',
        uf: '',
        city: '',
        username: '',
        password: '',
        rules: {
          name: [
            v => !!v || 'Obrigatório'
          ],
          phone: [
            v => !!v || 'Obrigatorio',
            v => (v && v.replace(/\D/g, '').length >= 10) || 'Telefone inválido. Ex: (##) # ####-####',
          ],
          email: [
            v => !!v || 'Obrigatorio',
            v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Inválido',
          ],
          preference: [
            v => !!v || 'Obrigatorio'
          ],
          username: [
            v => !!v || 'Obrigatorio',
            v => (v && v.length <= 25) || 'Inválido',
          ],
          password: [
            v => !!v || 'Obrigatório',
            v => (v && v.length >= 8) || 'A senha deve conter no mínimo 8 caracteres',
            v => /[A-Z]/.test(v) || 'A senha deve conter pelo menos uma letra maiúscula',
            v => /[a-z]/.test(v) || 'A senha deve conter pelo menos uma letra minúscula',
            v => /[0-9]/.test(v) || 'A senha deve conter pelo menos um número',
          ],
          acceptedTerms: [
            v => v || 'Você deve aceitar os termos e condições para se registrar.'
          ],
          city: [
            v => !!v || 'Cidade é obrigatória'
          ],
          uf: [
            v => !!v || 'UF é obrigatória',
          ],
          cpf: [
            v => (!this.form.cnpj && !!v) || (!!this.form.cnpj && !v) || 'CPF ou CNPJ é obrigatório',
            v => !v || /^[0-9]{11}$/.test(v) || 'CPF deve conter 11 dígitos numéricos'
          ],
          cnpj: [
            v => (!this.form.cpf && !!v) || (!!this.form.cpf && !v) || 'CNPJ ou CPF é obrigatório',
            v => !v || /^[0-9]{14}$/.test(v) || 'CNPJ deve conter 14 dígitos numéricos'
          ]
        }
      },
      show: false,
      acceptedTerms: false,
      loading: false,
      items: [
        {
          title: 'Como funciona a plataforma?',
          description: 'Conectamos de maneira completamente gratuita, consultores especializados nas áreas de Marketing, Finanças, Jurídico e RH, com micro e pequenos negócios que precisam de suporte profissional para reerguer suas empresas e superar os desafios impostos pelos eventos climáticos no RS.',
          isOpen: false
        },
        {
          title: 'É de graça?',
          description: 'Sim. Todas as consultorias são completamente gratuitas.',
          isOpen: false
        },
        {
          title: 'Como faço para participar das consultorias?',
          description: 'Preencha o formulário de cadastro ou faça login na plataforma, selecione o consultor voluntário de acordo com a sua necessidade (Marketing, Finanças, Jurídico ou RH) e inicie o contato via WhatsApp.',
          isOpen: false
        },
        {
          title: 'Quanto tempo duram as consultorias?',
          description: 'Existem três possibilidades de duração das consultorias: 45min, de 1 hora ou 1h30min. Esse alinhamento é feito diretamente com o consultor selecionado.',
          isOpen: false
        },
        {
          title: 'A consultoria é somente online? Por onde ela será feita?',
          description: 'Sim, as consultorias são somente online. O contato inicial com o consultor selecionado é feito via WhatsApp, podendo ser alinhado a partir dali, o canal de preferência para reunião (WhatsApp, Google, Teams, Zoom, etc).',
          isOpen: false
        },
        {
          title: 'Posso realizar mais de uma consultoria?',
          description: 'Sim, é possível fazer uma consultoria de cada uma das frentes de atuação (Marketing, RH, Jurídico, Financeiro).',
          isOpen: false
        },
      ],
      labelsHelper: ['Juridico', 'Financeiro', 'RH', 'Marketing']
    };
  },
  methods: {
    cleanMask(cpf) {
      return cpf.replace(/\D/g, '');
    },
    redirectLogin() {
      router.push('/login');
    },
    toggle(index) {
      this.items = this.items.map((item, i) => ({
        ...item,
        isOpen: i === index ? !item.isOpen : false
      }));
    },
    // register() {
    //   if (!this.$data.form.cpf && !this.$data.form.cnpj) {
    //     console.log("entrou no if")
    //     this.$dialog.notify.error("O campo CPF ou CNPJ deve ser preenchido",
    //       { position: 'top-right', outlined: false, flat: true, timeout: 5000 });
    //     return;
    //   }
    //   if (!this.acceptedTerms) {
    //     this.$dialog.notify.error("Você deve aceitar os termos e condições para se registrar.",
    //       { position: 'top-right', outlined: false, flat: true, timeout: 5000 });
    //     return;
    //   }
    //   const cpfWhitoutMak = this.cleanMask(this.$data.form.cpf);
    //   const cnphWhitoutMak = this.cleanMask(this.$data.form.cnpj);
    //   const phoneWithoutMask = this.cleanMask(this.$data.form.phone);
    //   this.$data.loading = true;

    //   axios
    //     .patch('/users',
    //       {
    //         operation: 'register',
    //         name: this.$data.form.name,
    //         email: this.$data.form.email,
    //         phone: phoneWithoutMask,
    //         username: this.$data.form.username,
    //         acceptedTerms: this.$data.acceptedTerms,
    //         cpf: cpfWhitoutMak,
    //         cnpj: cnphWhitoutMak,
    //         uf: this.$data.form.uf,
    //         city: this.$data.form.city,
    //         password: this.$data.form.password,
    //         // TODO: validate preferende field - default or new field
    //         preference: 1,
    //       })
    //     .then(e => {
    //       localStorage.setItem('token', JSON.stringify(e.data.data));

    //       var data = jwt(e.data.data.access);
    //       this.$root.user = data;
    //       localStorage.setItem('user', JSON.stringify(data));

    //       router.push("/search");
    //     })
    //     .catch(e => {
    //       this.$dialog.notify.error(this.$codes(e), { position: 'top-right', outlined: false, flat: true, timeout: 5000 });
    //       this.$data.loading = false;
    //     });
    // },
    handleResize() {
      this.isScreenWide = window.innerWidth > 600;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style scoped>
.container {
  display: contents;
  gap: 10px;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.primary-container {
  padding: 60px 71px;
  background-color: var(--primary-color1);
  color: var(--bg-color4);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .btn-group {
    justify-content: center !important;

    @media (min-width: 600px) {
      display: flex !important;

      .btn-item {
        padding-inline: 10px;
        padding-top: 10px;
      }
    }

    @media (max-width: 600px) {
      display: block !important;

      .btn-item {
        padding-inline: 10px;
        padding-top: 5px;
      }
    }
  }

  img {
    padding-right: 12px;
  }

  h1 {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  p {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
  }
}

.secondary-container {
  padding: 44px 58px 49px 58px;
  background-color: var(--bg-color7);
  color: var(--neutral-color1);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (min-width: 600px) {
    gap: 20px;
  }

  h1 {
    font-size: 17px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 24px;
    /* 141.176% */
  }

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
  }

  .labels-group {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center;
    gap: 12px;
    max-width: 100%;
    /* Permite que a largura máxima seja ajustável */
    margin: 0 auto;
  }

  .label-helper {
    gap: 12px;
  }

  .label-helper span {
    font-family: 'Inter', sans-serif !important;
    border: 1px solid var(--primary-color1);
    padding-bottom: 12px;
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--primary-color1);

    border-radius: 27px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    text-transform: uppercase;
  }

  @media (max-width: 600px) {
    .labels-group {
      flex-wrap: wrap !important;
      max-width: 200px;
    }
  }
}

.tertiary-container {
  padding: 60px 65px;
  background-color: var(--primary-color1);
  color: var(--bg-color4);
  gap: 24px;

  h1 {
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding-bottom: 24px;
    padding-top: 24px;
  }

  p {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    padding-bottom: 24px;
  }


  .labels-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    max-width: 200px;
    margin: 0 auto;
  }

  .label-helper {
    gap: 12px;

    span {
      font-family: 'Inter', sans-serif !important;
      border: 1px solid var(--neutral-color7);
      padding-bottom: 12px;
      display: flex;
      padding: 3px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-radius: 27px;
      border: 0.5px solid var(--neutral-color7);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);

      color: 0.5px solid var(--neutral-color7);
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      text-transform: uppercase;
    }
  }
}

.quaternary-container {
  padding: 40px 60px 45px 60px;
  background-color: var(--bg-color7);
  color: var(--neutral-color1);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  h1 {
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 141.176% */
  }

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
  }

  .field {
    border-radius: 8px;
  }

  .checkbox {
    label {
      color: #000;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
    }
  }
}

.quinary-container {
  padding: 70px 30px 70px 30px;
  background-color: var(--neutral-color6);
  color: var(--neutral-color1);

  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: var(--primary-color1);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding-bottom: 10px;
  }

  img {
    padding-inline: 6px;
  }

  .v-carousel {
    height: 150px !important;
    min-width: 300px !important;

    .v-carousel__controls {
      background-color: transparent !important;
    }

    .v-responsive__content {
      padding: 30px;
    }

    .theme--dark.v-btn.v-btn--icon {
      color: var(--neutral-color4);
      width: 0 !important;
      height: 0 !important;
      font-size: 4px !important;
    }

    .v-icon.notranslate.mdi.mdi-circle.theme--dark {
      font-size: 8px !important;
    }

    .v-btn__content {
      font-size: 4px !important;
    }
  }
}

.senary-container {
  padding: 54px, 36px;
  background-color: var(--neutral-color7);
  color: var(--neutral-color1);

  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: var(--primary-color1);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    /* 138.095% */
  }
}

.septenary-container {
  padding: 36px 54px;

  h1 {
    color: var(--primary-color1);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 20px;
  }

  .card {
    border-bottom: 1px solid var(--neutral-color5);
    gap: 20px;
    padding-bottom: 12px;
    text-align: initial;

    h2 {
      color: var(--neutral-color2);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-top: 12px;
    }

    .description {
      font-size: 14px;
      padding-top: 8px;
    }
  }
}

.footer {
  background-color: var(--primary-color1);
  padding: 27px 93px;

  h1 {
    color: var(--bg-color4);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  img {
    padding-right: 12px;
  }
}
</style>